/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/fonts/font_roboto_mono_main.css';
@import './assets/fonts/font_inter_main.css';

$content-max-width: 1400px;

@layer components {
  .btn-primary {
    @apply bg-emerald-400 text-white rounded-lg font-bold hover:bg-emerald-500 focus:outline-none focus:ring focus:ring-emerald-400 focus:ring-opacity-75;
    transition-duration: 0.15s;
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: ease-in;
  }

  .btn-primary-outline {
    @apply border border-solid border-emerald-400 text-emerald-400 rounded-lg font-bold hover:border-emerald-500 hover:text-emerald-500 focus:outline-none focus:ring focus:ring-emerald-400 focus:ring-opacity-75;
    transition-duration: 0.15s;
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: ease-in;
  }

  .dropdown_button {
    @apply text-white bg-emerald-400 hover:bg-emerald-500 focus:outline-none font-medium rounded-lg text-sm text-center inline-flex items-center border-emerald-400 border-solid border;
    transition-duration: 0.15s;
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: ease-in;
  }

  .btn-normal {
    @apply py-3 px-4 rounded-lg;
  }

  .bg-primary {
    @apply bg-sky-950;
  }

  .bg-secondary {
    background-color: #06263a;
  }

  .shadow_border {
    border-color: rgba(#34D399, 0.2);
  }

  .content{
    max-width: $content-max-width;
    margin: auto;
  }
}

body {
  @apply bg-sky-950;
}

:root {
  --swiper-pagination-color: #34D399;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

input[type=range]::-webkit-slider-thumb {
  background: #34d399; /* This is the color for emerald-400 in Tailwind CSS */
}

input[type=range]::-moz-range-thumb {
  background: #34d399;
}

input[type=range]::-ms-thumb {
  background: #34d399;
}


input[type=range]::-moz-range-progress {
  background: #34d399; /* This is the color for emerald-400 in Tailwind CSS */
}

input[type=range]::-ms-fill-upper {
  background: #34d399;
}

input[type=number] {
  -webkit-appearance: textfield; /* This removes the default browser styling */
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none; /* This hides the spinners */
  margin: 0; /* This removes the extra space created by the spinners */
}

@keyframes spin-slot {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(360deg);
  }
}

@keyframes spin-back-slot {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-360deg);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(6px);
  }
  60% {
    transform: translateY(3px);
  }
}

@keyframes press {
  0%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(6px);
  }
}

