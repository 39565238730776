/* inter-100 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-100.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-200 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-200.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-regular - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-300 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-300.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-500 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-600 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-800 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-800.svg#Inter') format('svg'); /* Legacy iOS */
}

/* inter-700 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./inter-v3-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./inter-v3-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./inter-v3-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./inter-v3-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./inter-v3-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./inter-v3-latin-ext_latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}
